.guess {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  .buttonRefresh {
    border: none;
    color: var(--text-color);
    background: var(--accent-color);
    padding: 10px;
    margin-right: 10px;
    font-weight: 700;
    font-size: 12px;
    font-family: Tahoma;
    margin: 0 auto;
    display: block;
    margin-bottom: 20px;
  }
  .guessBlock {
    padding: 10px;
    color: var(--text-color);
    max-width: 600px;
    background: #fff1;
    border-left: 9px solid gray;
    min-width: 300px;
    width: 100%;
    &.selected {
      background: #0f01;
      border-left: 9px solid limegreen;
    }
    .title {
      font-size: 24px;
      margin-bottom: 10px;
      min-width: 300px;
    }
    .description {
      margin-bottom: 10px;
    }
    .select {
      width: 100%;
      padding: 5px;
      background: rgba(0, 0, 0, 0.1);
      color: var(--text-color);
      margin-bottom: 10px;
      min-width: 300px;
      option {
        color: #111;
        background: #eee;
      }
    }
    .button {
      border: none;
      color: var(--text-color);
      background: var(--accent-color);
      padding: 10px;
      margin-right: 10px;
      margin-bottom: 10px;
      font-weight: 700;
      font-size: 16px;
      font-family: Tahoma;
    }
    .chosenText {
      display: flex;
      align-items: center;
      gap: 10px;
      // .icon {
      //   font-size: 30px;
      // }
      .guessers {
        display: flex;
        flex-direction: column;
        gap: 10px;
      }
    }
  }
}
