.results {
  padding-top: 20px;
  text-align: center;
  text-transform: uppercase;
  padding-bottom: 50px;
  .medal {
    font-size: 100px;
    margin-bottom: 20px;
  }
  h1,
  h2,
  h3 {
    margin: 0;
    margin-top: 8px;
    margin-bottom: 20px !important;
  }
  .separator {
    height: 10px;
  }
  .statsGrid3,
  .statsGrid4 {
    .statsCell {
      .statTitle {
        font-size: 16px;
      }
      .statValue {
        font-size: 24px;
        padding: 10px;
        .red {
          color: firebrick;
        }
        .green {
          color: forestgreen;
        }
        .yellow {
          color: rgb(234, 121, 8);
        }
      }
    }
  }
  .statsGrid3 {
    display: grid;
    grid-column-gap: 10px;
    grid-template-columns: repeat(3, 1fr);
    margin: 0 auto;
    background: #fff1;
    padding: 5px;
  }
  .statsGrid4 {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 10px;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 40px;
    margin: 0 auto;
    margin-top: 40px;
    margin-bottom: 20px;
  }
  .nominations {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 20px;
  }
  .reviews {
    margin-top: 20px;
    .review {
      text-align: left;
      text-indent: 1em;
      font-style: italic;
      text-transform: none;
      padding: 10px;
      border-radius: 10px;
      background: #fff2;
      max-width: 600px;
      margin: 0 auto;
      margin-bottom: 10px;
      .reviewRow {
        margin-bottom: 5px;
      }
    }
  }
  .identity {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    table {
      border-collapse: collapse;
      width: 400px;
      tr {
      }
      th,
      td {
        border: 1px solid var(--text-color);
        padding: 4px 10px;
      }
    }
  }
  .betsForMe {
    display: flex;
    justify-content: center;
    gap: 20px;
    font-size: 50px;
    margin-top: 10px;
  }
  .bets {
    margin-bottom: 10px;
  }
}
