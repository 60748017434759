.rules {
  color: var(--text-color);
  h3 {
    margin-top: 40px !important;
    text-align: center;
  }
  .line {
    line-height: 1.5em;
    max-width: 800px;
    margin: 0 auto;
    margin-bottom: 10px;
    text-indent: -24px;
    padding-left: 20px;
    &::before {
      content: '★';
      padding-right: 10px;
    }
  }
  .listItem {
    max-width: 800px;
    margin: 0 auto;
    line-height: 1.5em;
    margin-bottom: 10px;
  }
}

.grid4 {
  text-align: center;
  margin: 0 auto;
  max-width: 960px;
  margin-top: 40px;

  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.nominations {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 20px;
  text-align: center;
  max-width: 900px;
  margin: 0 auto;
  margin-bottom: 20px;
  margin-top: 20px;
}
