.kot {
  .wrapper {
    margin: 0 auto;
    // margin-top: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    // height: 200px;
    margin-bottom: 20px;
    img {
      object-fit: cover;
      width: 300px;
    }
    img:nth-child(2) {
      position: relative;
      top: -50px;
      overflow: hidden;
      object-fit: cover;
      width: 300px;
      height: 50px;
      object-position: bottom;
    }
  }
}
