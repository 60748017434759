.textsAdmin {
  .textBlock {
    padding: 10px;
    background: rgba(255, 255, 255, 0.1);
    border-left: 4px solid transparent;
    margin-bottom: 40px;
    &:nth-child(2n) {
      background: rgba(0, 0, 0, 0.1);
    }
    .texttitle {
      font-size: 20px;
      font-weight: 700;
      margin-bottom: 5px;
    }
    &.gold {
      border-left: 4px solid gold;
    }
    &.silver {
      border-left: 4px solid gainsboro;
    }
    &.bronze {
      border-left: 4px solid salmon;
    }
    .statsGrid2 {
      grid-template-columns: repeat(2, 1fr);
      width: 200px;
      margin: 0 auto;
      margin-bottom: 20px;
      background: #fff1;
      padding: 5px;
    }
    .statsGrid3 {
      grid-template-columns: repeat(3, 1fr);
      margin: 0 auto;
      background: #fff1;
      padding: 5px;
    }
    .statsGrid4 {
      grid-template-columns: repeat(4, 1fr);
      grid-column-gap: 40px;
      width: 1000px;
      margin: 0 auto;
    }
    .statsGrid2,
    .statsGrid3,
    .statsGrid4 {
      text-align: center;
      display: grid;
      .statsCell {
        .statTitle {
          font-size: 16px;
        }
        .statValue {
          font-size: 24px;
          padding: 10px;
          .red {
            color: firebrick;
          }
          .green {
            color: forestgreen;
          }
          .yellow {
            color: rgb(234, 121, 8);
          }
        }
      }
    }
    .reviews {
      margin-top: 20px;
      .review {
        padding: 10px 0;
        border-bottom: 1px solid #fff3;
      }
    }
  }
  .topsTexts {
    margin-bottom: 40px;
    font-size: 14px;
    .cheat {
      text-align: right;
      margin-bottom: 20px;
      margin-top: 20px;
    }
    .topLine {
      gap: 10px;
      display: grid;
      grid-template-columns: 60px 80px 100px 1fr 40px 1fr auto;
      align-items: center;
      border-bottom: 2px solid #0007;
      padding: 10px 0;
      &:hover {
        background: #fff2;
      }
      &.mine {
        color: var(--accent-color);
      }
      a {
        font-weight: 300;
      }
      div:last-child {
        text-align: right;
      }
    }
  }
  .tops {
    .topLine {
      display: flex;
      gap: 10px;
      display: grid;
      grid-template-columns: 40px 80px 1fr 1fr;
      border-bottom: 1px solid #fff3;
      padding: 5px 0;
      &.mine {
        color: var(--accent-color);
      }
      a {
        font-weight: 300;
      }
    }
    h3 {
      margin-top: 20px;
      margin-bottom: 10px;
    }
    .red {
      color: firebrick;
    }
    .green {
      color: forestgreen;
    }
    .yellow {
      color: rgb(234, 121, 8);
    }
  }
  .totals {
    margin-top: 40px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 40px;
    .totalBlock {
      min-width: 320px;
      text-align: center;
      h3 {
        font-size: 24px;
      }
      .description {
        font-size: 12px;
      }
      .icon {
        font-size: 60px;
      }
      .count {
        font-size: 40px;
      }
    }
  }
}
