:root {
  --background-color: #f3effd;
  --text-color: #121416d8;
  --text-color-invert: #f7f8f8;
  --link-color: #4228e7;
  --accent-color: #e1daff;
  --background: url('../public/img/bee_light.jpg');
}

html[data-theme='dark'] {
  --background-color: #212a2e;
  --text-color: #dedede;
  --text-color-invert: #121416d8;
  --link-color: #ffaff3;
  --accent-color: #d6448d;
  --background: url('../public/img/bee_dark.jpg');
}

html[data-theme='bee'] {
  --background-color: #fffeabf9;
  --text-color: #414035d8;
  --text-color-invert: #f7f8f8;
  --link-color: #5c4f0f;
  --accent-color: #ffd700;
  --background: url('../public/img/bee2.jpg');
}

html[data-theme='watermelon'] {
  // --background-color: #8dbf86f9;
  --background-color: #9de092f9;
  --text-color: #273921d8;
  --text-color-invert: #f7f8f8;
  --link-color: #185c0f;
  --accent-color: #a7ff99f9;
  --background: url('../public/img/watermelon.jpg');
}

html[data-theme='pushki'] {
  --background-color: #f1b4efee;
  --text-color: #301e37d8;
  --text-color-invert: #f7f8f8;
  --link-color: #89467a;
  --accent-color: #dc2166f9;
  --background: url('../public/img/pushki.jpg');
}

html[data-theme='pushki_dark'] {
  --background-color: #212a2ecc;
  --text-color: #dedede;
  --text-color-invert: #121416d8;
  --link-color: #faafff;
  --accent-color: #975ded;
  --background: url('../public/img/pushki_dark.jpg');
}

html[data-theme='blins'] {
  --background-color: #ffd5abe7;
  --text-color: #4a2e26d8;
  --text-color-invert: #f7f8f8;
  --link-color: #5c3b0f;
  --accent-color: #ffefcd;
  --background: url('../public/img/blins_bg.jpg');
}

html[data-theme='flowers'] {
  --background-color: #f59bc9e7;
  --text-color: #301e37d8;
  --text-color-invert: #f7f8f8;
  --link-color: #89467a;
  --accent-color: #fdb4fff9;
  --background: url('../public/img/flowers.jpg');
}
