.invite {
  padding: 20px;
  margin: 10px;
  border-radius: 20px;
  box-shadow: 0 0 25px #0007;
  font-family: Tahoma;
  color: var(--text-color);
  background: var(--background-color);
  padding-bottom: 100px;

  h1 {
    text-align: center;
    text-wrap: wrap;
    margin-bottom: 40px;
  }
  h2 {
    text-align: center;
    margin-top: 40px;
  }

  .statistics {
    max-width: 600px;
    margin: 0 auto;
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
    margin-top: 40px;
    margin-bottom: 40px;
    .statBlock {
      padding: 10px;
      .title {
        font-size: 20px;
      }
      .value {
        font-size: 80px;
      }
    }
  }

  .contact {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 20px;
    div {
      min-width: 400px;
      max-width: 600px;
    }
    input {
      background-color: #111;
      color: white;
      padding: 10px;
      font-size: 16px;
      min-width: 400px;
      max-width: 600px;
      border: none;
    }
    .about {
      transition: 0.25s ease;
      div {
        margin-bottom: 20px;
      }
      &.hidden {
        height: 0;
        opacity: 0;
      }
      &.showed {
        height: 160px;
        opacity: 1;
      }
    }
    textarea {
      background-color: #111;
      color: white;
      padding: 10px;
      font-size: 16px;
      min-width: 400px;
      max-width: 600px;
      border: none;
      height: 100px;
    }
    button {
      padding: 10px 20px;
      border: none;
      color: white;
      background-color: #d6448d;
      font-size: 24px;
      transition: 0.25s ease;
      &:disabled {
        opacity: 0.25;
      }
    }
  }

  .isSent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 20px;
    background: rgba(21, 255, 0, 0.2);
    padding: 20px;
    transition: 0.25s ease;
    max-width: 600px;
    margin: 0 auto;
    .icon {
      font-size: 60px;
    }
    .text {
      font-size: 20px;
    }
  }

  .gif {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    margin-top: 100px;
    img {
      max-width: 300px;
    }
  }
}
