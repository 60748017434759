.playersInfo {
  table {
    width: 100%;
    border-collapse: collapse;
    tr {
    }
    th,
    td {
      border: 1px solid var(--text-color);
      padding: 4px;
      .miniTable {
        td {
          width: 16%;
        }
      }
    }
  }
}

.green {
  background: #228b2299;
}
.red {
  background: #b2222299;
}
