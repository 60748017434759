@import url('./Theme.scss');
@import url('./Screen.scss');

body {
  background: var(--background);
  background-size: 100%;
  background-attachment: fixed;
  overflow-y: scroll;
  background-size:cover a {
    color: var(--link-color);
    font-weight: 700;
  }
  a {
    color: var(--link-color);
  }
}

@media screen and (max-width: 1470px) {
  body {
    background-size: 200%;
  }
}
@media screen and (max-width: 730px) {
  body {
    background-size: 300%;
  }
}
@media screen and (max-width: 490px) {
  body {
    background-size: 400%;
  }
}

.App {
  margin: 0 auto;
  h1 {
    font-size: 2.5rem;
    text-align: center;
    margin: 0;
    color: rgb(255, 245, 157);
    text-shadow: 0 0 20px #000;
    text-wrap: nowrap;
  }
}

.profile {
  padding: 20px;
  margin: 10px;
  border-radius: 20px;
  box-shadow: 0 0 25px #0007;
  font-family: Tahoma;
  color: var(--text-color);
  background: var(--background-color);
  h2 {
    margin: 0;
    text-align: center;
    font-size: 40px;
    margin-bottom: 10px;
    font-weight: 500;
  }
  h3 {
    margin: 0;
    margin-bottom: 10px;
  }
  .clanInfo {
    text-align: center;
    font-size: 18px;
    border-bottom: 1px solid #7777;
    padding-bottom: 10px;
    margin-bottom: 10px;
  }
  .profile_chart {
    max-width: 600px;
    min-width: 280px;
    height: 300px;
    margin: 0 auto;
    text-align: center;
    margin-bottom: 60px;
  }
}

.texts {
  .texts_stat {
    text-align: center;
    font-size: 14px;
    margin-top: 20px;
  }
  .textBlock {
    min-width: 280px;
    max-width: 800px;
    margin: 0 auto;
    padding: 10px;
    background: rgba(255, 255, 255, 0.1);
    display: flex;
    flex-direction: column;
    gap: 10px;
    &:nth-child(2n) {
      background: rgba(0, 0, 0, 0.1);
    }
    .texttitle {
      font-size: 20px;
      font-weight: 700;
      margin-bottom: 5px;
    }
    .textButton {
      border: none;
      color: var(--text-color);
      background: var(--accent-color);
      padding: 5px 10px;
      margin-top: 10px;
      margin-right: 10px;
      font-weight: 700;
      font-size: 16px;
    }
  }
  .badges {
    .badge {
      padding: 5px 10px;
      padding-bottom: 8px;
      border-radius: 10px;
      color: white;
      font-weight: 700;
      display: inline-block;
      margin-right: 5px;
      margin-top: 10px;
      font-size: 12px;
      &.red {
        background: firebrick;
      }
      &.green {
        background: forestgreen;
      }
      &.purple {
        background: purple;
      }
      &.yellow {
        background: rgb(234, 121, 8);
      }
    }
  }
}

.indexPage {
  color: var(--text-color);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: 20px;
  text-align: center;
  flex-direction: column;
  gap: 40px;
  a {
    padding: 20px;
    background: var(--accent-color);
    color: var(--text-color);
  }
}

.votes {
  .votesTitleBig {
    text-align: center;
    font-size: 40px;
  }
  .votesTitleSmall {
    text-align: center;
    font-size: 24px;
    margin-bottom: 20px;
  }
  table {
    border-collapse: collapse;
    margin: 0 auto;
    td {
      border: 1px solid var(--text-color);
      padding: 5px 10px;
    }
  }
  .vote {
    padding: 5px 0;
    span.rating {
      color: black;
      display: inline-block;
      padding: 0px 10px;
      border-radius: 5px;
      &.gold {
        background: gold;
      }
      &.orange {
        background: orangered;
      }
      &.red {
        background: firebrick;
      }
    }
  }
}

.strong {
  font-weight: 700;
}

.flex {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.TiltAnimation {
  animation: tiltFrames 1s steps(2) infinite;
}

.FloatAnimation {
  animation: floatFrames 3s linear infinite;
}

@keyframes tiltFrames {
  0% {
    transform: rotate(-5deg);
  }

  100% {
    transform: rotate(5deg);
  }
}
@keyframes floatFrames {
  0% {
    transform: translateY(5px);
  }
  50% {
    transform: translateY(-5px);
  }
  100% {
    transform: translateY(5px);
  }
}

@keyframes ufo-move {
  0% {
    transform: scale(0.2);
    opacity: 0;
  }
  20% {
    transform: scale(1);
    opacity: 1;
  }
  25% {
    opacity: 1;
  }
  95% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

.ufoButton {
  font-size: 20px;
  opacity: 0.33;
  cursor: pointer;
}

.ufoAnimated {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  left: 0;
  top: 0;
  position: fixed;
  width: 100vw;
  height: 100vh;
  font-size: 20px;
  transition: 3s ease;
  animation: ufo-move 5s ease 1;
  font-size: 120px;
  &.purple {
    background: #606b;
  }
  &.green {
    background: #006600bb;
  }
  &.blue {
    background: #002766bb;
  }
  .ufo_text {
    font-size: 20px;
    text-align: center;
    color: white;
  }
}

@keyframes clan-shake {
  0% {
    transform: scale(1) rotate(-1deg);
  }
  50% {
    transform: scale(1.1) rotate(1deg);
  }
  100% {
    transform: scale(1) rotate(-1deg);
  }
}

.domination {
  animation: clan-shake 1s linear infinite;
  text-shadow: 0 0 20px var(--accent-color);
}
