.bets {
  max-width: 800px;
  margin: 0 auto;
  .betRow {
    // display: grid;
    // grid-template-columns: 200px auto 160px;
    // grid-column-gap: 10px;
    border-bottom: 1px solid #fff3;
    padding: 10px;
    &.greenLine {
      background: #32cd3233;
    }
    &.redLine {
      background: #cd323233;
    }
    &.red {
      color: firebrick;
    }
  }

  .total {
    display: flex;
    justify-content: center;
    gap: 40px;
    margin-bottom: 20px;
    .totalBlock {
      display: flex;
      flex-direction: column;
      h2 {
        font-size: 24px;
      }
      .totalCoins {
        display: flex;
        justify-content: center;
        gap: 10px;
        font-size: 24px;
        text-align: center;
      }
    }
  }
}
