@keyframes progressBar {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 80px 0;
  }
}

.game {
  .progress {
    justify-content: center;
    max-width: 960px;
    margin: 0 auto;
    h3 {
      text-align: center;
      margin-top: 20px;
    }
    .progressBar {
      margin: 0 auto;
      height: 40px;
      min-width: 320px;
      max-width: 400px;
      margin-bottom: 40px;
      .title {
        text-align: center;
        margin-bottom: 5px;
        font-size: 18px;
        font-weight: 700;
      }
      .await {
        background-color: firebrick;
        background: url('../../../../public/img/progress2.jpg');
        animation: progressBar 1s linear infinite;
        height: 40px;
      }
      .filled {
        background: url('../../../../public/img/progress.jpg');
        animation: progressBar 1s linear infinite;
        position: relative;
        bottom: 40px;
        height: 40px;
      }
    }
  }
  .instruction {
    text-align: center;
    font-size: 20px;
    max-width: 600px;
    margin: 0 auto;
    margin-top: 100px;
    margin-bottom: 20px;
  }
  .percent {
    // margin-top: 10px;
    text-align: center;
    .title {
      text-transform: uppercase;
      font-weight: 700;
      font-size: 28px;
    }
    .number {
      font-size: 60px;
      margin-bottom: 5px;
    }
  }
  .deadline {
    margin-top: 40px;
    h3 {
      text-align: center;
      margin-bottom: 20px;
    }
    .warning {
      color: red;
      text-shadow: 0 0 10px black;
      background: black;
      display: flex;
      justify-content: center;
      align-items: center;
      max-width: 500px;
      margin: 0 auto;
      margin-bottom: 20px;
      div {
        max-width: 500px;
        font-size: 30px;
        text-transform: uppercase;
        padding: 10px;
        text-align: center;
        display: inline-block;
        width: auto;
        margin: 0;
      }
    }
    .timer {
      display: grid;
      grid-template-columns: 100px 20px 100px 20px 100px;
      grid-column-gap: 5px;
      max-width: 600px;
      margin: 0 auto;
      text-align: center;
      justify-content: center;
      margin-bottom: 100px;
      div {
        font-size: 48px;
        text-align: center;
        &.number {
          background: #111;
          color: white;
        }
      }
    }
  }
  .texts {
    .textBlock {
      border-left: 4px solid transparent;
      &:nth-child(1) {
        border-left: 4px solid gold;
      }
      &:nth-child(2) {
        border-left: 4px solid gainsboro;
      }
      &:nth-child(3) {
        border-left: 4px solid salmon;
      }
    }
    .rating {
      font-size: 16px;
      font-weight: 700;
      margin-bottom: 10px;
    }
  }
}
