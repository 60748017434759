.page {
  padding: 20px;
  margin: 10px;
  border-radius: 20px;
  box-shadow: 0 0 25px #0007;
  font-family: Tahoma;
  color: var(--text-color);
  background: var(--background-color);
  .selectText {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    .texts {
      display: flex;
      flex-direction: column;
      gap: 10px;
      align-items: center;
      .gText {
        filter: hue-rotate(-80deg) !important;
      }
    }
    button,
    a {
      color: var(--text-color);
      font-size: 20px;
      // padding: 10px 20px;
      min-width: 300px;
      padding-top: 10px;
      border: none;
      transition: 0.2s ease;
      cursor: pointer;
      text-decoration: none;
      background: var(--accent-color);
      &:hover {
        filter: brightness(1.25);
      }
    }
    a {
      display: block;
      .flex {
        display: flex;
        flex-direction: column;
        gap: 5px;
        align-items: center;
        .title {
          padding: 0 20px;
        }
        .characters {
          font-size: 16px;
          padding-bottom: 5px;
        }
        .button {
          text-align: center;
          // background: yellow;
          padding: 0 10px;
          padding-bottom: 10px;
          padding-top: 5px;
          width: calc(100% - 20px);
          background: var(--accent-color);
          filter: brightness(0.75);
        }
      }
    }
    button {
      background: forestgreen;
      color: #eee;
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }
}

.selectText {
  h2 {
    text-align: center;
  }
}

.editor {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  .wrapper {
    background: white;
    padding: 10px;
    max-width: 960px;
    min-width: 320px;
    width: 960px;
    h2 {
      margin: 0;
      text-align: center;
    }
    .backLink {
      height: 21px;
      text-align: center;
      a {
        color: navy;
      }
    }
    .title {
      display: flex;
      justify-content: start;
      align-items: center;
      gap: 20px;
      padding: 10px 0;
      flex-wrap: wrap;
      input {
        min-width: 300px;
        max-width: 400px;
        width: 100%;
        // width: 400px;
        padding: 5px;
      }
    }
    .footer {
      display: flex;
      flex-direction: column;
      gap: 5px;
      align-items: center;
      .characters {
      }
      button {
        color: var(--text-color);
        font-size: 20px;
        padding: 10px 20px;
        border: none;
        transition: 0.2s ease;
        cursor: pointer;
        text-decoration: none;
        background: var(--accent-color);
        &:disabled {
          opacity: 0.25;
          cursor: not-allowed;
        }
        &:hover {
          filter: brightness(1.25);
        }
        &.deleteButton {
          background: firebrick;
          color: white;
        }
      }
      .informer {
      }
    }
  }
}

.accessError {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background: #b222224e;
  padding: 20px;
  color: white;
  h2 {
    margin: 0;
    text-align: center;
  }
  .error {
    font-size: 24px;
  }
  .backLink {
    height: 21px;
    text-align: center;
    a {
      color: white;
    }
  }
}
