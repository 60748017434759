.season {
  padding: 20px;
  margin: 10px;
  border-radius: 20px;
  box-shadow: 0 0 25px #0007;
  font-family: Tahoma;
  color: var(--text-color);
  background: var(--background-color);
  padding-bottom: 100px;

  h1 {
    text-align: center;
    text-wrap: wrap;
  }
  h2 {
    text-align: center;
  }

  .wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  ul {
    text-align: center;
    list-style: none;
    li {
      padding: 5px 0;
    }
  }

  p {
    max-width: 960px;
    margin: 0 auto;
    line-height: 24px;
  }

  .imgWrapper {
    display: flex;
    justify-content: center;
    margin-top: 40px;
    img {
      max-width: 400px;
    }
  }
}
