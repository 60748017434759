.rulesBase {
  h3 {
    margin-top: 40px !important;
    text-align: center;
  }
  .line {
    line-height: 1.5em;
    max-width: 800px;
    margin: 0 auto;
    margin-bottom: 10px;
    text-indent: -24px;
    padding-left: 20px;
    &::before {
      content: '★';
      padding-right: 10px;
    }
  }
  table {
    border-collapse: collapse;
    margin: 0 auto;
    margin-top: 20px;
    td {
      border: 1px solid var(--text-color);
      padding: 5px 10px;
    }
  }
}
