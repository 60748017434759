.guessers {
  .flex {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
  }
  .namesTotal {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    justify-content: center;
    margin-bottom: 40px;
  }
  table {
    border-collapse: collapse;
    width: 400px;
    tr {
    }
    th,
    td {
      border: 1px solid var(--text-color);
      padding: 4px 10px;
    }
  }
}
