.bets {
  .ufoSecret {
    padding-top: 100px;
    text-align: right;
  }
  h3 {
    text-align: center;
  }

  .balance {
    max-width: 400px;
    margin: 0 auto;
    margin-top: 20px;
    .coinblock {
      display: flex;
      justify-content: center;
      align-items: center;
      .coinIcon {
        margin-right: 20px;
      }
      .coinAmount {
        font-size: 24px;
      }
    }
  }

  .totalizator {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;
    margin-top: 40px;
    span {
      font-size: 16px;
    }
    .selectText,
    .selectCoin {
      padding: 5px;
      background: rgba(0, 0, 0, 0.1);
      color: var(--text-color);
      option {
        color: #111;
        background: #eee;
      }
    }
    input {
      padding: 8px;
      background: rgba(0, 0, 0, 0.1);
      color: var(--text-color);
      border: none;
      width: 30px;
    }
    button {
      // padding: 8px;
      // background: rgba(0, 0, 0, 0.1);
      // color: var(--text-color);
      // border: none;

      border: none;
      color: var(--text-color);
      background: var(--accent-color);
      padding: 5px 10px;
      font-weight: 700;
      font-size: 16px;
      font-family: Tahoma;

      &:disabled {
        background: gray;
      }
    }
  }

  .suggestedBets {
    width: fit-content;
    margin: 0 auto;
    margin-top: 20px;
    .bet {
      padding: 10px;
      background: rgba(255, 255, 255, 0.1);
      margin-bottom: 5px;
    }
  }
}
