.modal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background: #111c;
  display: flex;
  justify-content: center;
  align-items: center;
  .form {
    display: flex;
    flex-direction: column;
    gap: 20px;
    h2 {
    }
    .formFields {
      display: flex;
      flex-direction: column;
      gap: 10px;
      .formField {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 10px;
        input {
          width: 300px;
          padding: 10px;
          background: #333;
          color: white;
          border: none;
        }
      }
    }
    button {
      background: var(--accent-color);
      color: var(--text-color);
      font-size: 30px;
      padding: 10px 20px;
      border: none;
      transition: 0.2s ease;
      cursor: pointer;
      &:hover {
        filter: brightness(1.25);
      }
    }
  }
}
