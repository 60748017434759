.reviewModal {
  .wrapper {
    background: #000c;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    .modal {
      background: var(--background-color);
      padding: 20px;
      border-radius: 20px;
      h3 {
      }
      textarea {
        width: calc(100% - 10px);
        border: none;
        padding: 5px;
        min-height: 50vh;
      }
      .informer {
        margin-top: 10px;
      }
      button {
        border: none;
        color: var(--text-color);
        background: var(--accent-color);
        padding: 10px;
        // float: right;
        margin-top: 10px;
        width: 100%;
      }
    }
  }
}
