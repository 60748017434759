.library {
  .header {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    flex-wrap: wrap;
    margin-bottom: 20px;
    text-align: center;
    h2 {
      margin-bottom: 5px;
    }
    img {
      width: 60px;
    }
  }
  .controls {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-bottom: 20px;
    input {
      width: 300px;
      padding: 5px;
      background: #333;
      color: white;
      border: none;
    }
  }
  .buttons {
    display: flex;
    justify-content: center;
    gap: 5px;
    margin-bottom: 20px;
    button {
      background: var(--accent-color);
      color: var(--text-color);
      padding: 10px 5px;
      border: none;
      font-weight: 700;
      max-width: 120px;
      cursor: pointer;
      text-transform: uppercase;
      font-size: 12px;
      &.selected {
        filter: brightness(1.25);
      }
    }
  }
  .texts {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
  }
  .statistics {
    margin-top: 40px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 40px;
    margin-bottom: 50px;
    .totalBlock {
      min-width: 220px;
      text-align: center;
      h3 {
        font-size: 24px;
      }
      .description {
        font-size: 12px;
      }
      .icon {
        font-size: 60px;
      }
      .count {
        font-size: 40px;
      }
      .countSmall {
        font-size: 20px;
      }
    }
  }
}
