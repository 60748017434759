.tabs {
  margin-top: 20px;
  margin-bottom: 20px;
  color: var(--text-color);
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;

  .tabLink {
    cursor: pointer;
    padding: 5px 10px;
    text-wrap: nowrap;
    &:hover {
      background: #ffffff77;
    }
    &.selected {
      cursor: default;
      background: var(--accent-color);
      color: var(--text-color);
    }
  }
}
