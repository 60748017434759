.adminService {
  display: flex;
  flex-direction: column;
  gap: 16px;

  .controlBlock {
    border: 2px solid gray;
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: start;
    h3 {
      text-transform: uppercase;
      margin: 0;
    }
    button {
      background: #111;
      color: white;
      border: 2px solid gray;
      padding: 8px 16px;
      text-transform: uppercase;
      &:disabled {
        opacity: 0.25;
      }
    }
    .green {
      text-transform: uppercase;
      color: limegreen;
    }
    .red {
      text-transform: uppercase;
      color: firebrick;
    }
  }
}
