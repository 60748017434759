.hallOfFame {
  text-align: center;
  .userRow {
    margin-bottom: 20px;
    padding: 20px 0;
    div {
      margin-bottom: 5px;
    }
    hr {
      margin-top: 20px;
    }
    .userGrid {
      // display: flex;
      justify-content: space-between;
      .balls {
        text-align: center;
        margin-top: 20px;
        .ballsCount {
          font-size: 80px;
          margin-top: -10px;
        }
      }
    }
    &:nth-child(2n) {
      background: rgba(0, 0, 0, 0.1);
    }
  }
}

.rounds {
  display: flex;
  flex-direction: column;
  gap: 50px;
  .player {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    border-bottom: 1px solid gray;
    padding-bottom: 40px;
    h3 {
      font-weight: 300;
      font-size: 40px;
    }
    .playerRounds {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 30px;
      .playerRound {
        .playerRoundTitle {
          margin-bottom: 10px;
          font-weight: 700;
          // text-align: left;
        }
        .playerRoundTexts {
          display: flex;
          flex-direction: column;
          align-items: start;
          gap: 10px;
          min-width: 320px;
          // width: 600px;
          .playerRoundText {
            max-width: 600px;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 10px;
            flex-wrap: wrap;
          }
        }
      }
    }
    .playerBalls {
      display: flex;
      justify-content: center;
      max-width: 600px;
      gap: 60px;
      flex-wrap: wrap;
      .bigNumber {
        font-size: 60px;
      }
    }
  }
}

.achievemenets {
  display: flex;
  flex-direction: column;
  gap: 40px;
  .player {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    padding-bottom: 40px;
    border-bottom: 1px solid gray;
    h3 {
      font-weight: 300;
      font-size: 40px;
    }
    .achievements {
      max-width: 800px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      flex-wrap: wrap;
    }
  }
}

.themes {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  .round {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    background: #fff1;
    padding: 10px;
    min-width: 320px;
    max-width: 600px;
    width: 100%;
    // width: 400px;
    .roundNumber {
      font-size: 60px;
    }
    .themes {
      display: flex;
      flex-direction: column;
      min-width: 200px;
      max-width: 400px;
      width: 100%;
      gap: 10px;
      align-items: end;
      text-align: right;
      .theme {
      }
    }
    .players {
      display: flex;
      flex-direction: column;
      gap: 10px;
      .big {
        font-size: 30px;
      }
    }
  }
}

.badge {
  padding: 2px 10px;
  padding-bottom: 4px;
  border-radius: 10px;
  color: white;
  font-weight: 500;
  display: inline-block;
  width: 90px;
  &.purple {
    background: purple;
  }
  &.gold {
    background: gold;
    color: black;
  }
  &.silver {
    background: gainsboro;
    color: black;
  }
  &.copper {
    background: salmon;
    color: black;
  }
  &.gray {
    background: gray;
  }
  &.black {
    background: #111;
    color: white;
  }
}
