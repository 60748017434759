.adminTextGuesses {
  .namesTotal {
    display: flex;
    flex-wrap: wrap;
    gap: 40px;
    justify-content: center;
    margin-bottom: 40px;
  }
  .list {
    display: flex;
    flex-wrap: wrap;
    gap: 40px;
    justify-content: center;
    .text {
      display: flex;
      flex-direction: column;
      gap: 10px;
      h3 {
        margin: 0;
        text-align: center;
      }
      .author {
        text-align: center;
      }
    }
  }
  table {
    border-collapse: collapse;
    width: 400px;
    tr {
    }
    th,
    td {
      border: 1px solid var(--text-color);
      padding: 4px 10px;
    }
  }
}
