.extraVoteBlocks {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  .extraVoteBlock {
    // margin-top: 10px;
    // margin-bottom: 10px;
    font-size: 12px;
    // border-left: 2px solid gold;
    padding-left: 10px;
    min-width: 160px;
    .title {
      margin-bottom: 5px;
      text-transform: uppercase;
      font-size: 16px;
      font-weight: 700;
      text-align: center;
    }
    .description {
      margin-bottom: 10px;
      text-align: center;
    }
    .buttons {
      display: flex;
      flex-direction: column;
      gap: 10px;
      button {
        border: none;
        color: white;
        background: var(--accent-color);
        padding: 10px;
        font-weight: 700;
        font-family: Tahoma;
        text-transform: uppercase;
        &:hover {
          cursor: pointer;
          filter: brightness(1.2);
        }
        &.red {
          background: #b22222bb;
        }
        &.green {
          background: #228b22bb;
        }
        &.yellow {
          background: #ea7908bb;
        }
      }
    }
  }
}
