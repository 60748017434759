.text {
  padding: 10px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  background: #8a3cd8a5;
  min-width: 250px;
  max-width: 250px;
  &.green {
    background: #3f35;
  }
  &.dead {
    // background: #34393455;
  }
  .textRound {
    font-size: 12px;
  }
  .textTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: left;
    a {
      font-weight: 700;
    }
    .textRound {
      text-wrap: nowrap;
      background: gold;
      text-align: center;
      color: black;
      padding: 4px 8px;
      border-radius: 16px;
      display: inline-block;
      margin-right: 5px;
    }
  }
  .name {
    text-align: left;
  }
  .textPlace {
    text-align: left;
  }
}
