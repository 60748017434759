.nominations {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  .nomination {
    border-left: 9px solid gray;
    background: #fff1;
    width: 100%;
    max-width: 800px;
    &.selected {
      background: #0f01;
      border-left: 9px solid limegreen;
    }
    .nominationBlock {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      .icon {
        display: flex;
        justify-content: center;
        max-width: 180px;
        width: 180px;
      }
      .info {
        padding: 10px;
        color: var(--text-color);
        display: flex;
        flex-direction: column;
        gap: 10px;
        max-width: 600px;
        width: 100%;

        .title {
          font-size: 24px;
          margin-bottom: 10px;
          min-width: 300px;
        }
        .description {
          margin-bottom: 10px;
        }
        .select {
          width: 100%;
          padding: 5px;
          background: rgba(0, 0, 0, 0.1);
          color: var(--text-color);
          margin-bottom: 10px;
          option {
            color: #111;
            background: #eee;
          }
        }
        .button {
          border: none;
          color: var(--text-color);
          background: var(--accent-color);
          padding: 10px;
          margin-right: 10px;
          margin-bottom: 10px;
          font-weight: 700;
          font-size: 16px;
          font-family: Tahoma;
        }
        .buttonInformer {
        }
      }
    }
  }
}
