:root .App {
  max-width: 1200px;
}

html[data-screen='medium'] .App {
  max-width: 1200px;
}

html[data-screen='wide'] .App {
  max-width: 1600px;
}

html[data-screen='thin'] .App {
  max-width: 960px;
}
