.controls {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
  input {
    width: 300px;
    padding: 5px;
    background: #333;
    color: white;
    border: none;
  }
}

.players {
  display: flex;
  flex-direction: column;
  gap: 40px;

  .player {
    // display: flex;
    // gap: 20px;
    // justify-content: space-between;
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 2fr 2fr 2fr 1fr;
    border-bottom: 2px solid silver;

    & > div {
      border-right: 2px solid silver;
      // padding: 20px;
      &:last-child {
        border-right: none;
      }
    }

    .playerInfo {
      min-width: 200px;
      display: flex;
      flex-direction: column;
      gap: 10px;
      padding-right: 20px;
      padding-bottom: 20px;
    }
    .playerTexts {
      // min-width: 240px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 5px;
      padding: 10px;
      button {
        background: var(--accent-color);
        color: var(--text-color);
        padding: 10px 0;
        border: none;
        transition: 0.2s ease;
        cursor: pointer;
        margin-bottom: 20px;
        width: 270px;
        &:hover {
          filter: brightness(1.25);
        }
      }
    }
    .playerAchievements {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;
      padding: 10px;
      textarea {
        width: 100%;
        height: 200px;
      }
    }
    .playerButtons {
      display: flex;
      flex-direction: column;
      gap: 5px;
      padding: 10px;
      button {
        background: var(--accent-color);
        color: var(--text-color);
        font-size: 16px;
        padding: 10px 5px;
        border: none;
        font-weight: 700;
        max-width: 120px;
      }
    }
  }
}

.greenCircle,
.redCircle {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  display: inline-block;
}
.greenCircle {
  background: limegreen;
}
.redCircle {
  background: firebrick;
}
