.wrapper {
  display: flex;
  flex-direction: column;
}

.nomination {
  min-width: 120px;
  min-height: 140px;
  text-align: center;
  user-select: none;
  padding: 5px;
  cursor: pointer;
  border: 2px solid transparent;
  text-transform: none !important;
  &:hover {
    background: #fff2;
  }
  &.bg {
    padding: 10px;
    background: #fff1;
  }
  &.border {
    border: 2px solid var(--accent-color);
  }
  .icon {
    font-size: 60px;
    margin-bottom: 5px;
  }
  .imgIcon {
    height: 82px;
    &.imgIconSmall {
      height: 50px;
    }
    &.imgIconExtraSmall {
      height: 20px;
      background: red;
    }
  }
  .title {
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .count {
    font-size: 24px;
  }
  &.small {
    // width: 60px;
    min-width: 40px;
    min-height: 40px;
    .icon {
      font-size: 40px;
      margin-bottom: 5px;
    }
    .count {
      font-size: 16px;
    }
  }
  &.extraSmall {
    min-width: 20px;
    min-height: 20px;
    .icon {
      font-size: 20px;
    }
  }
}

.winner {
  text-align: center;
  text-transform: uppercase;
  font-weight: 700;
  color: var(--accent-color);
  padding-top: 5px;
}

.infoModal {
  background: #000c;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  .info {
    background: var(--background-color);
    color: var(--text-color);
    padding: 20px 40px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center !important;
    max-width: 280px;
    .itemType {
      text-transform: uppercase;
      font-weight: 700;
    }
    .itemPeriodType {
      // text-transform: uppercase;
    }
    h2 {
      font-size: 80px;
      margin: 0;
    }
    .imgIcon {
      // width: 120px;
    }
    h3 {
      font-size: 24px !important;
      margin: 0;
    }
    .winText {
      color: var(--accent-color);
      font-weight: 700;
      margin-top: 20px;
      margin-bottom: 20px;
    }
    button {
      margin-top: 20px;
      border: none;
      font-size: 20px;
      padding: 10px 20px;
      color: var(--text-color);
      background: var(--accent-color);
    }
  }
}
