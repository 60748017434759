.settings {
  margin: 0 auto;
  .ufoSecret {
    text-align: center;
  }
  h3 {
    text-align: center;
    margin-right: 10px;
  }
  .pushkiLink {
    margin-bottom: 40px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    a {
      text-decoration: none;
    }
    img {
      width: 400px;
      max-width: 300px;
    }
    .button {
      min-width: 160px;
      max-width: 380px;
      text-align: center;
      padding: 10px 40px;
      background: #dd2782f9;
      border: 1px solid grey;
      cursor: pointer;
      text-transform: uppercase;
      font-weight: 700;
      margin-top: 10px;
      color: white;
    }
  }
  .settingsBlock {
    margin-bottom: 40px;
    .buttons {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      gap: 10px;
      max-width: 800px;
      margin: 0 auto;
      .buttonWrapper {
        button {
          display: flex;
          flex-direction: column;
          align-items: center;
          min-width: 120px;
          text-align: center;
          margin-right: 10px;
          background: var(--bg-color);
          border: none;
          cursor: pointer;
          text-transform: uppercase;
          font-weight: 700;
          img {
            width: 120px;
          }
          div {
            padding: 10px;
            width: 100%;
          }
          &.light {
            div {
              background: #f3effd;
            }
          }
          &.dark {
            div {
              background: #212a2e;
              color: white;
            }
          }
          &.bee {
            div {
              background: #fffeabf9;
            }
          }
          &.watermelon {
            div {
              background: #9de092f9;
            }
          }
          &.pushki {
            div {
              background: #dd2782f9;
              color: white;
            }
          }
          &.pushki_dark {
            div {
              background: #3f1d70f9;
              color: white;
            }
          }
          &.blins {
            div {
              background: #e17c39f9;
              color: white;
            }
          }
          &.flowers {
            div {
              background: #f86de5f9;
              color: white;
            }
          }
        }
        .text {
          text-align: center;
          text-transform: uppercase;
          margin-top: 5px;
          font-weight: 700;
          font-size: 14px;
        }
      }
    }
  }
  .iconSettingsBlock {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 10px;
    .iconButton {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 160px;
      padding: 10px;
      cursor: pointer;
      img {
        width: 120px;
      }
      div {
        text-transform: uppercase;
        text-align: center;
        font-weight: 700;
      }
      &.hidden {
        display: none;
      }
    }
  }
  .nameChange {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 5px;

    button {
      text-align: center;
      padding: 10px 40px;
      background: #dd2782f9;
      border: 1px solid grey;
      cursor: pointer;
      text-transform: uppercase;
      font-weight: 700;
      color: white;
      font-size: 16px;
      &:disabled {
        background: gray;
        opacity: 0.5;
      }
    }
    .clanInfo {
      max-width: 600px;
      min-width: 400px;
      line-height: 1em;
      margin-bottom: 20px;
      background: #1112;
      padding: 10px;
    }
    div {
      text-align: center;
      font-size: 20px;
    }
    .newName {
      font-size: 20px;
      font-weight: 700;
      text-transform: uppercase;
    }
  }

  .footer {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;
    .text {
      margin-top: 5px;
      text-align: center;
      color: var(--text-color);
      a {
        color: royalblue;
      }
    }
  }
}
