.review {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  .header {
    font-size: 18px;
  }
  .reviewBody {
    display: flex;
    flex-direction: column;
    gap: 10px;
    text-align: left;
    // text-indent: 1em;
    font-style: italic;
    text-transform: none;
    padding: 10px;
    padding-bottom: 6px;
    border-radius: 10px;
    background: #fff2;
    max-width: 600px;
    margin-bottom: 10px;
    .reviewRow {
      margin-bottom: 5px;
      line-height: 24px;
    }
  }
}
