.achievemnts {
  h2 {
    margin-bottom: 40px;
  }
  .achievemntsList {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    .achievemntBlock {
      width: 370px;
      display: flex;
      align-items: center;
      justify-content: start;
      border: 1px solid gray;
      padding: 10px 0;
      .achievemntBlockTitle {
        margin-bottom: 5px;
        font-weight: 700;
        text-transform: uppercase;
        color: var(--accent-color);
      }
      .achievemntBlockUsers {
        display: flex;
        flex-direction: column;
        gap: 5px;
      }
      .achievemntBlockIcon {
        min-width: 80px;
      }
    }
  }
  .texts {
    display: flex;
    flex-direction: column;
    gap: 10px;
    .userBlock {
      display: flex;
      gap: 10px;
      .userName {
        min-width: 250px;
      }
      .userCount {
        min-width: 20px;
      }
      .userCode {
        min-width: 1200px;
        input {
          width: 100%;
          background: #111;
          color: #eee;
          border: none;
          padding: 5px;
        }
      }
    }
  }
}
