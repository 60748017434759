.nameChange {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 5px;

  button {
    text-align: center;
    padding: 10px 40px;
    background: #dd2782f9;
    border: 1px solid grey;
    cursor: pointer;
    text-transform: uppercase;
    font-weight: 700;
    color: white;
    font-size: 16px;
    &:disabled {
      background: gray;
      opacity: 0.5;
    }
  }
  .clanInfo {
    max-width: 600px;
    min-width: 300px;
    line-height: 1em;
    margin-bottom: 20px;
    background: #1112;
    padding: 10px;
  }
  div {
    text-align: center;
    font-size: 20px;
  }
  .newName {
    font-size: 20px;
    font-weight: 700;
    text-transform: uppercase;
  }
}

.clanPage {
  .instruction {
    min-width: 320px;
    max-width: 600px;
    margin: 0 auto;
    text-align: center;
    margin-bottom: 20px;
  }
  .clan {
    border: 2px solid firebrick;

    margin: 0 auto;
    max-width: 320px;
    background: #f003;
    &.myClan {
      min-width: 320px;
      max-width: 800px;
      border: 2px solid forestgreen;
      background: #228b2233;
      .clanHeader {
        background: #228b2255;
        h2 {
          font-size: 40px;
        }
        .clanPower {
          font-size: 24px;
        }
        .clanInfo {
          min-height: auto;
          padding-bottom: 10px;
        }
      }
    }
    .clanHeader {
      background: #f005;
      padding: 10px;
      h2 {
        margin-bottom: 0;
        font-size: 30px;
      }
      .clanPower {
        text-align: center;
        font-size: 20px;
        margin-bottom: 20px;
      }
      .clanInfo {
        font-style: italic;
        min-height: 80px;
      }
    }
    .clanUsers {
      padding: 10px;
      h3 {
        margin: 0 auto;
        max-width: 400px;
        margin-bottom: 10px;
      }
      .clanUser {
        margin: 0 auto;
        max-width: 400px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 20px;
        border-bottom: 1px solid gray;
        padding: 4px 0;
        &:last-child {
          border-bottom: none;
        }
        .power {
          text-wrap: nowrap;
        }
      }
    }
  }
  .anotherClans {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 40px;
    gap: 10px;
  }
}
