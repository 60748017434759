.winners {
  .texts {
    .textBlock {
      padding: 20px 10px;
      background: rgba(255, 255, 255, 0.1);
      text-align: center;
      h2 {
      }
      .balls {
        font-size: 30px;
        margin-bottom: 20px;
      }
      .nominations {
        margin-top: 20px;
      }
      .extraPlaces {
        margin-top: 20px;
        div {
          margin: 5px 0;
        }
      }
      .guesses {
        margin-top: 20px;
      }
      &:nth-child(2n) {
        background: rgba(0, 0, 0, 0.1);
      }
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      &:nth-child(1) {
        border-left: 8px solid gold;
        border-right: 8px solid gold;
      }
      &:nth-child(2) {
        border-left: 8px solid gainsboro;
        border-right: 8px solid gainsboro;
      }
      &:nth-child(3) {
        border-left: 8px solid salmon;
        border-right: 8px solid salmon;
      }
    }
  }
}
