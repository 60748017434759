.modal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background: #111e;
  display: flex;
  justify-content: center;
  align-items: center;
}

.reviewsModal {
  display: flex;
  flex-direction: column;
  gap: 20px;
  text-align: center;
  .header {
    text-align: center;
  }
  .reviews {
    max-width: 800px;
    width: 800px;
    max-height: 60vh;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    gap: 10px;

    .review {
      text-align: left;
      text-indent: 1em;
      font-style: italic;
      text-transform: none;
      padding: 10px;
      border-radius: 10px;
      background: #fff2;
      max-width: 600px;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      gap: 10px;
      width: 100%;
      .reviewRow {
        margin-bottom: 5px;
      }
    }
  }
  .noReviews {
    text-align: center;
    font-size: 20px;
  }
  button {
    background: var(--accent-color);
    color: var(--text-color);
    font-size: 30px;
    padding: 10px 20px;
    border: none;
    transition: 0.2s ease;
    cursor: pointer;
    &:hover {
      filter: brightness(1.25);
    }
  }
}
