.coinsAdmin {
  .checkboxes {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  table {
    border-collapse: collapse;
    margin: 0 auto;
    td {
      text-align: center;
      padding: 2px 4px;
      border: 1px solid var(--text-color);
      button {
        border: none;
        margin-right: 4px;
        padding: 4px 8px;
        font-weight: 700;
      }
    }
  }
}
