.reader {
  padding: 20px;
  margin: 10px;
  border-radius: 20px;
  box-shadow: 0 0 25px #0007;
  font-family: Tahoma;
  color: var(--text-color) !important;
  background: var(--background-color);

  display: flex;
  flex-direction: column;
  gap: 20px;

  .title {
    text-align: center;
    font-size: 36px;
  }
  .characters {
    text-align: center;
  }
  .text {
    margin: 0 auto;
    max-width: 600px;
    line-height: 20px;
    hyphens: auto;
    * {
      color: var(--text-color) !important;
      background-color: transparent !important;
    }
  }
}
