.statsGrid3 {
  grid-template-columns: repeat(3, 1fr);
  margin: 0 auto;
  background: #fff1;
  padding: 5px;
}

.statsGrid2,
.statsGrid3,
.statsGrid4 {
  text-align: center;
  display: grid;
}

.statTitle {
  font-size: 16px;
}
.statValue {
  font-size: 24px;
  padding: 10px;
}

.red,
.green,
.yellow {
  padding: 0 5px;
}

.red {
  color: firebrick;
}
.green {
  color: forestgreen;
}
.yellow {
  color: rgb(234, 121, 8);
}
