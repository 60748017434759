.bio {
  .hint {
    text-align: center;
    font-size: 30px;
    margin-bottom: 20px;
  }
  .editorBlock {
    margin-bottom: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    .editorHint {
      text-align: center;
      font-size: 20px;
    }
    button {
      border: none;
      color: var(--text-color);
      background: var(--accent-color);
      padding: 10px;
      margin-right: 10px;
      margin-bottom: 10px;
      font-weight: 700;
      font-size: 16px;
      font-family: Tahoma;
      margin-top: 20px;
    }
  }
  .stages {
    margin-top: 20px;
    min-width: 300px;
    max-width: 600px;
    margin: 0 auto;
    // background: #2135;
    padding: 20px;
    margin-bottom: 20px;
    table {
      border-collapse: collapse;
      tr:nth-child(2) {
        position: relative;
        top: -3px;
      }
      tr:nth-child(3) {
        position: relative;
        top: -6px;
      }
      img {
        width: 100%;
      }
    }
  }
  .stats {
    text-align: center;
    margin-bottom: 60px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 10px;
    .statBlock {
      border: 2px solid gray;
      padding: 10px;
      min-width: 360px;
      &.yellow {
        border: 2px solid gold;
        background: #ffd70033;
      }
      &.green {
        border: 2px solid limegreen;
        background: #32cd3233;
      }
      .statBlockTitle {
        font-size: 20px;
        margin-bottom: 10px;
      }
      .statBlockGrid {
        display: flex;
        justify-content: space-around;
        gap: 10px;
        .statBlockValue {
          min-width: 60px;
          .statBlockValueTitle {
            font-size: 14px;
          }
          .statBlockValueNumber {
            font-size: 28px;
          }
        }
      }
      .instruction {
        font-size: 14px;
        margin-top: 10px;
      }
    }
  }
  h2 {
    margin: 0;
    margin-top: 20px;
    text-align: center;
    font-size: 40px;
    margin-bottom: 10px;
    font-weight: 500;
  }
  h3 {
    margin: 0;
    margin-bottom: 10px;
  }
}
